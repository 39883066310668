<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card style="padding: 3rem; margin-top: 30vh;" vertical>
      <template>
        <v-row no-gutters>
          <v-col></v-col>
        </v-row>
        <div class="v-footer no-gutters">
          <LoadingBar v-if="loading === true" />
          <template v-else>
            <v-layout
              align-center
              justify-center
              v-if="this.roles.includes('new')"
            >
              Client portal access will be limited until your new account is
              approved. An email will be sent to you once your account is
              approved.
            </v-layout>

            <v-layout
              align-center
              justify-center
              v-else-if="this.roles.includes('denied')"
            >
              Unfortunately, Your registered account has been denied, Thanks for
              your interest in CVAS.
            </v-layout>
          </template>
        </div>
      </template>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";

/**
 * @property {int} currentStep
 */
export default {
  name: "AccountStepWizard",
  components: {
    LoadingBar
  },
  data() {
    return {
      loading: false,
      valid: false // This gets updated automatically, via the validator
    };
  },
  computed: {
    ...mapGetters("registration", ["steps"]),
    ...mapFields("registration", ["wizard.currentStep", "wizard.returnStep"]),
    ...mapFields("auth", ["user.roles"]),
    ...mapFields("auth", ["user"]),
    // Returns the current step component instance
    currentStepComponent() {
      if (!this.$refs) return undefined;
      // Find the current component (by ref="componentName")
      // currentStep is ahead of the index by 1
      let component = this.$refs[this.steps[this.currentStep - 1].component];
      if (!component) return undefined;
      if (Array.isArray(component)) {
        // The component will most likely be in an array, but it'll only have one item in it
        component = component[0];
      }
      return component;
    },
    canContinue() {
      return this.valid;
    }
  },
  methods: {
    ...mapActions("clients", ["getClients"]),
    ...mapActions("baseData", [
      "getCommunicationTypes",
      "getLocales",
      "getUnitTypes",
      "getLabs",
      "getReportLayouts",
      "getReportTemplates",
      "getReportTemplateTypes"
    ]),
    previousStep() {
      this.currentStep = this.currentStep - 1;
    },
    // Proceed to the next step
    nextStep(n) {
      // If validation doesn't succeed, we can stop now
      if (!this.$refs.form.validate()) return false;
      // If component has beforeContinue method, execute it. Otherwise, we can safely set to true.
      const beforeContinue =
        this.currentStepComponent && this.currentStepComponent.beforeContinue
          ? this.currentStepComponent.beforeContinue()
          : true;
      // Resolve as a promise, that way we can do async things when needed
      Promise.resolve(beforeContinue).then(res => {
        // As long as we don't have a false response, we can proceed to the next step
        if (res) {
          // If returnStep contains a step, go to that step
          if (this.returnStep && typeof this.returnStep === "number") {
            // this.currentStep = this.returnStep;
            this.currentStep = this.steps.length; // Use last step every time for now (to resolve dynamic steps issues)
            this.returnStep = false; // Reset returnStep value
            return true;
          }
          // Default to next step iteration
          this.currentStep = n + 1;

          if (this.currentStep > this.steps.length) {
            if (!this.isRoleClient()) {
              this.$router.push("/setup/confirm");
            }
          }
        }
      });
    },
    isRoleClient() {
      return this.roles.includes("client") ? true : false;
    },
    displaySubmit() {
      // if (this.currentStep === this.steps.length) {
      if (this.isRoleClient()) {
        return false;
      }
      // }
      else return true;
    }
  },
  mounted() {
    this.loading = true;
    // If approved client, load review for account management
    if (this.isRoleClient()) {
      this.currentStep = this.steps.length;
    }

    Promise.all([
      this.getClients()
      // this.getCommunicationTypes(),
      // this.getLocales(),
      // this.getUnitTypes(),
      // this.getLabs(),
      // this.getReportLayouts(),
      // this.getReportTemplates(),
      // this.getReportTemplateTypes()
    ]).then(() => (this.loading = false));
  }
};
</script>
