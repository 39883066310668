<template>
  <AccountSetupWizard />
</template>

<script>
import AccountSetupWizard from "@/components/registration/AccountSetupWizard";
export default {
  name: "AccountSetup",
  components: { AccountSetupWizard }
};
</script>

<style scoped></style>
