<template>
  <v-col cols="12" sm="8">
    <v-row>
      <template v-if="!hasBilling">
        <v-col cols="12" sm="6">
          <h4>Billing Address</h4>
          <BillingAccount />
        </v-col>
      </template>
      <v-col
        cols="12"
        sm="6"
        v-for="address in clients_addresses"
        :key="address.clients_address_type_id"
      >
        <h4>
          {{
            (
              getById(
                "clients_address_types",
                address.clients_address_type_id
              ) || {}
            ).title
          }}
          Address
        </h4>
        <BillingAccount v-if="isBilling(address)" :checkbox-only="true" />
        <v-checkbox
          v-model="useMainAddress"
          :value="address.clients_address_type_id"
          label="Is this the same as the main address?"
        ></v-checkbox>
        <v-text-field
          v-model="address.address_1"
          :rules="rules.required"
          label="Address 1"
          :disabled="usesMainAddress(address)"
        ></v-text-field>
        <v-text-field
          v-model="address.address_2"
          label="Address 2"
          :disabled="usesMainAddress(address)"
        ></v-text-field>
        <v-text-field
          v-model="address.city"
          :rules="rules.required"
          label="City"
          :disabled="usesMainAddress(address)"
        ></v-text-field>
        <v-select
          v-model="address.country_id"
          :rules="rules.required"
          :items="countries"
          item-value="id"
          item-text="country_name"
          label="Country"
          no-data-text="No Countries Available"
          :disabled="usesMainAddress(address)"
        ></v-select>
        <v-select
          v-if="filterById('states', address.country_id, 'country_id').length"
          v-model="address.state_id"
          :rules="rules.required"
          :items="filterById('states', address.country_id, 'country_id')"
          item-value="id"
          item-text="title"
          label="State/Province"
          no-data-text="No States/Provinces Available"
          :disabled="usesMainAddress(address)"
        ></v-select>
        <v-text-field
          v-model="address.postal_code"
          :rules="rules.postal_code"
          label="Postal Code"
          :disabled="usesMainAddress(address)"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import rules from "@/utils/rules";
import BillingAccount from "@/components/clients/Addresses/BillingAccount";

export default {
  name: "Addresses",
  components: { BillingAccount },
  data() {
    return {
      rules: {
        required: [rules.required],
        email: [rules.email],
        postal_code: [rules.maxLength(10), rules.required]
      }
    };
  },
  computed: {
    ...mapGetters("baseData", [
      "countries",
      "states",
      "clients_address_types",
      "filterById",
      "getById"
    ]),
    ...mapMultiRowFields("clients", ["items[0].clients_addresses"]),
    ...mapFields("clients", [
      "useMainAddress",
      "billing_only_client",
      "items[0].third_party_billing"
    ]),
    hasBilling() {
      const billingTypeId = (
        this.getById("clients_address_types", "Billing", "title") || {}
      ).id;
      return this.clients_addresses.find(
        address => address.clients_address_type_id === billingTypeId
      );
    }
  },
  methods: {
    ...mapMutations("clients", ["removeAddress"]),
    ...mapActions("clients", [
      "getClients",
      "updateClientAddresses",
      "getBillingOnlyClientId"
    ]),
    ...mapActions("baseData", ["getClientsAddressTypes"]),
    usesMainAddress(address) {
      return this.useMainAddress.includes(address.clients_address_type_id);
    },
    isBilling(address) {
      return (
        this.getById("clients_address_types", address.clients_address_type_id)
          .title === "Billing"
      );
    },
    beforeContinue() {
      return this.updateClientAddresses().catch(e => {
        console.error(e);
        return false;
      });
    }
  }
};
</script>

<style>
.selectedBilling * {
  color: #006940 !important;
}
</style>
