<template>
  <v-container class="ma-0 pa-0">
    <v-card-text class="headline">
      Account # <code>{{ selectedClient.id || "Loading..." }}</code>
    </v-card-text>
    <v-card-text class="body-1 pb-2">
      Please include your account number on all sample submissions and
      correspondence.
    </v-card-text>
    <v-row>
      <v-col cols="12" class="pb-0">
        Business Name: {{ selectedClient.title || "N/A" }}
      </v-col>
      <v-col cols="12" class="pb-0">
        First Name: {{ selectedClient.first_name || "N/A" }}
      </v-col>
      <v-col cols="12" class="pb-2">
        Last Name: {{ selectedClient.last_name || "N/A" }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountIdCardContent",
  computed: {
    ...mapGetters("clients", ["selectedClient"])
  }
};
</script>

<style scoped></style>
