<template>
  <StepComponent>
    <v-row>
      <v-col cols="12" md="6">
        <AccountInformation />
      </v-col>
      <v-col cols="12" md="6">
        <ContactInformation />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <Addresses />
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col><LabSelection /></v-col>
        </v-row>
        <v-row>
          <v-col><ReportingPreferences /></v-col>
        </v-row>
        <v-row v-if="!selectedClient.third_party_billing">
          <v-col><InvoiceTerms /></v-col>
        </v-row>
      </v-col>
    </v-row>
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/registration/AccountSetupSteps/StepComponent";
import AccountInformation from "@/components/registration/ReviewStepCards/AccountInformation";
import ContactInformation from "@/components/registration/ReviewStepCards/ContactInformation";
import Addresses from "@/components/registration/ReviewStepCards/Addresses";
import LabSelection from "@/components/registration/ReviewStepCards/LabSelection";
import ReportingPreferences from "@/components/registration/ReviewStepCards/ReportingPreferences";
import InvoiceTerms from "@/components/registration/ReviewStepCards/InvoiceTerms";
import { mapGetters } from "vuex";

export default {
  name: "ReviewStep",
  components: {
    StepComponent,
    AccountInformation,
    ContactInformation,
    Addresses,
    LabSelection,
    ReportingPreferences,
    InvoiceTerms
  },
  computed: {
    ...mapGetters("clients", ["selectedClient"])
  }
};
</script>

<style scoped></style>
