import { render, staticRenderFns } from "./ReportingPreferences.vue?vue&type=template&id=28bda2bd&scoped=true"
import script from "./ReportingPreferences.vue?vue&type=script&lang=js"
export * from "./ReportingPreferences.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bda2bd",
  null
  
)

export default component.exports