<template>
  <v-col cols="12" sm="4">
    <h4>Main Address</h4>
    <v-text-field
      v-model="address_1"
      :rules="rules.required"
      label="Address 1"
    ></v-text-field>
    <v-text-field v-model="address_2" label="Address 2"></v-text-field>
    <v-text-field
      v-model="city"
      :rules="rules.required"
      label="City"
    ></v-text-field>
    <v-select
      v-model="country_id"
      :rules="rules.required"
      :items="countries"
      item-value="id"
      item-text="country_name"
      label="Country"
      no-data-text="No Countries Available"
    ></v-select>
    <v-select
      v-if="filterById('states', country_id, 'country_id').length"
      v-model="state_id"
      :rules="rules.required"
      :items="filterById('states', country_id, 'country_id')"
      item-value="id"
      item-text="title"
      label="State/Province"
      no-data-text="No States/Provinces Available"
    ></v-select>
    <v-text-field
      v-model="postal_code"
      :rules="rules.postal_code"
      label="Postal Code"
    ></v-text-field>
  </v-col>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations } from "vuex";
import rules from "@/utils/rules";

// Create a computed field that commits to updateAddressField mutation
const createAddressComputed = function(field) {
  return {
    get() {
      return this.$store.getters["clients/selectedClient"][field];
    },
    set(val) {
      let type_ids = this.useMainAddress;
      this.updateAddressField({ field, val, type_ids });
    }
  };
};

const createAddressComputedFields = function(fields) {
  return fields.reduce((output, field) => {
    output[field] = createAddressComputed(field);
    return output;
  }, {});
};

export default {
  name: "ClientAddress",
  data() {
    return {
      rules: {
        required: [rules.required],
        email: [rules.email],
        postal_code: [rules.maxLength(10), rules.required]
      }
    };
  },
  computed: {
    ...mapGetters("baseData", [
      "countries",
      "states",
      "clients_address_types",
      "filterById"
    ]),
    ...mapFields("clients", [
      "useMainAddress",
      "billing_only_client",
      "items[0].third_party_billing",
      "items[0].clients_third_party_billing"
    ]),
    ...createAddressComputedFields([
      "address_1",
      "address_2",
      "city",
      "state_id",
      "country_id",
      "postal_code"
    ])
  },
  methods: {
    ...mapMutations("clients", ["updateAddressField", "initUseMainAddress"])
  },
  watch: {
    useMainAddress(val) {
      this.initUseMainAddress(val);
    }
  }
};
</script>

<style scoped></style>
