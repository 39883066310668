<template>
  <StepComponent>
    <v-row align-content="center" justify="center">
      <v-col>
        <div>Receive Reports Via</div>
        <template v-for="communication_type in filteredCommunicationTypes">
          <v-list-item
            v-if="communication_type.name === 'Fax'"
            :key="communication_type.id"
          >
            <v-tooltip right :disabled="!!fax">
              <template v-slot:activator="{ on }">
                <div v-on="on" class="d-inline-block">
                  <v-checkbox
                    :label="communication_type.name"
                    :value="communication_type.id"
                    v-model="report_communication_types_selected"
                    :disabled="!!!fax"
                    :rules="rules.receive_report"
                  />
                </div>
              </template>
              <span>
                A fax number was not entered during the contact information
                step.
              </span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-else :key="communication_type.id">
            <v-checkbox
              :label="communication_type.name"
              :value="communication_type.id"
              v-model="report_communication_types_selected"
              :rules="rules.receive_report"
            />
          </v-list-item>
        </template>
      </v-col>
      <v-col v-if="report_layouts.length">
        <div>Report Layouts</div>
        <template v-for="report_layout in report_layouts">
          <v-list-item :key="report_layout.id">
            <v-checkbox
              :label="report_layout.title"
              :value="report_layout.id"
              v-model="report_layouts_selected"
              :rules="rules.report_layout"
            />
          </v-list-item>
        </template>
      </v-col>
      <v-col>
        <v-autocomplete
          :items="this.locales"
          :filter="languageFilter"
          label="Language"
          no-data-text="No matches were found"
          item-text="name"
          item-value="code"
          v-model="locale_code"
        ></v-autocomplete>
        <v-select
          :items="unit_types"
          label="Units"
          item-text="name"
          item-value="id"
          no-data-text="No matches were found"
          v-model="unit_type_id"
        ></v-select>
        <v-checkbox
          label="Hold Report for Entire Package"
          v-model="release_full_package"
        />
        <v-checkbox
          label="Merge Reports by Farm Name"
          v-model="merge_reports_by_farm"
        />
      </v-col>
    </v-row>
    <v-row align-content="center" justify="center">
      <v-col v-if="report_templates.length">
        <div>Reports</div>
        <template v-for="report_template in report_templates">
          <v-list-item :key="report_template.id">
            <v-checkbox
              :label="report_template.title"
              :value="report_template.id"
              v-model="report_templates_selected"
            />
          </v-list-item>
        </template>
      </v-col>
      <v-col v-if="report_template_types.length">
        <div>Specialty Reports</div>
        <template v-for="report_template_type in report_template_types">
          <v-list-item :key="report_template_type.id">
            <v-checkbox
              :label="report_template_type.name"
              :value="report_template_type.id"
              v-model="report_template_types_selected"
            />
          </v-list-item>
        </template>
      </v-col>
      <v-col>
        <!--        //here to provide for even layout until an extra item is needed-->
      </v-col>
    </v-row>
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/registration/AccountSetupSteps/StepComponent";
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "@/utils/rules";

export default {
  name: "ReportingPreferences",
  components: { StepComponent },
  data() {
    return {
      rules: {
        receive_report: [
          rules.required,
          v => (v && v.length >= 1) || `Must select at least one`
        ],
        report_layout: [
          rules.required,
          v => (v && v.length >= 1) || `Must select at least one`
        ]
      },
      valid: true
    };
  },
  computed: {
    ...mapFields("clients", [
      "items[0].fax",
      "items[0].report_communication_types",
      "items[0].report_layouts",
      "report_communication_types_selected",
      "report_layouts_selected",
      "report_templates_selected",
      "report_template_types_selected",
      "items[0].unit_type_id",
      "items[0].merge_reports_by_farm",
      "items[0].release_full_package",
      "items[0].locale_code"
    ]),
    ...mapGetters("baseData", [
      "communication_types",
      "locales",
      "unit_types",
      "report_layouts",
      "report_templates",
      "report_template_types"
    ]),
    filteredCommunicationTypes() {
      return this.communication_types.filter(item => !!item.is_report);
    }
  },
  methods: {
    ...mapActions("clients", ["updateReportingPreferences"]),
    languageFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1;
    },
    beforeContinue() {
      return this.updateReportingPreferences().catch(e => {
        console.error(e);
        return false;
      });
    }
  },
  mounted() {
    if (this.locale_code === null) {
      this.locale_code = "en_US";
    }
  }
};
</script>

<style scoped></style>
