<template>
  <StepComponent>
    <v-row>
      <v-col>
        <AccountIdCardContent />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="title"
          label="Business Name"
          :rules="rules.businessOrNames"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="first_name"
          label="First Name"
          :rules="rules.businessOrFirstName"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="last_name"
          label="Last Name"
          :rules="rules.businessOrLastName"
        ></v-text-field>
      </v-col>
    </v-row>
  </StepComponent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StepComponent from "@/components/registration/AccountSetupSteps/StepComponent";
import AccountIdCardContent from "@/components/clients/account/AccountIdCardContent";
import { mapFields } from "vuex-map-fields";

export default {
  name: "AccountInformationStep",
  components: { StepComponent, AccountIdCardContent },
  computed: {
    ...mapGetters("clients", ["selectedClient"]),
    ...mapFields("clients", [
      "items[0].title",
      "items[0].first_name",
      "items[0].last_name"
    ]),
    rules() {
      let componentRules = {};
      const businessOrName = field => {
        return () => {
          return (
            !!this.title ||
            !!field ||
            "Business Name or First/Last Name are required"
          );
        };
      };

      const businessOrNames = () => {
        return (
          !!this.title ||
          !!this.first_name ||
          !!this.last_name ||
          "Business Name or First/Last Name are required"
        );
      };
      // Separating these into individual componentRules, that way current field with a value won't show validation errors
      componentRules.businessOrNames = [businessOrNames];
      componentRules.businessOrFirstName = [businessOrName(this.first_name)];
      componentRules.businessOrLastName = [businessOrName(this.last_name)];
      return componentRules;
    }
  },
  methods: {
    ...mapActions("clients", ["updateAccountInfo"]),
    beforeContinue() {
      return this.updateAccountInfo().catch(e => {
        console.error(e);
        return false;
      });
    },
    resetValidation() {
      this.$emit("resetValidation");
    }
  }
};
</script>
