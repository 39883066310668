<template>
  <BaseCard
    title="Contact Information"
    :step-component="'ContactInformationStep'"
  >
    <v-row>
      <v-col cols="12" class="pb-0">
        Business Phone: {{ selectedClient.work_phone || "N/A" }}
      </v-col>
      <v-col cols="12" class="pb-0">
        Home Phone: {{ selectedClient.home_phone || "N/A" }}
      </v-col>
      <v-col cols="12" class="pb-0">
        Mobile Phone: {{ selectedClient.mobile_phone || "N/A" }}
      </v-col>
      <v-col cols="12"> Fax Number: {{ selectedClient.fax || "N/A" }} </v-col>
    </v-row>
  </BaseCard>
</template>

<script>
import { mapGetters } from "vuex";
import BaseCard from "@/components/registration/ReviewStepCards/BaseCard";

export default {
  name: "ContactInformation",
  components: { BaseCard },
  computed: {
    ...mapGetters("clients", ["selectedClient"])
  }
};
</script>

<style scoped></style>
