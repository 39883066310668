<template>
  <v-row>
    <LoadingBar v-if="loading" />
    <template v-else>
      <ClientAddress />
      <ClientAddresses />
    </template>
  </v-row>
</template>

<script>
import LoadingBar from "@/components/loaders/LoadingBar";
import ClientAddress from "@/components/clients/Addresses/ClientAddress";
import ClientAddresses from "@/components/clients/Addresses/ClientAddresses";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ClientAddressesContainer",
  components: { LoadingBar, ClientAddress, ClientAddresses },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters("baseData", ["clients_address_types"])
  },
  methods: {
    ...mapActions("clients", ["getClients", "initClientAddresses"]),
    ...mapActions("baseData", ["getClientsAddressTypes"])
  },
  mounted() {
    this.loading = true;
    Promise.all([this.getClients(), this.getClientsAddressTypes()]).then(() => {
      return this.initClientAddresses().then(() => {
        this.loading = false;
      });
    });
  }
};
</script>

<style scoped></style>
