<template>
  <BaseCard
    title="Account Information"
    :step-component="'AccountInformationStep'"
  >
    <AccountIdCardContent />
  </BaseCard>
</template>

<script>
import { mapGetters } from "vuex";
import BaseCard from "@/components/registration/ReviewStepCards/BaseCard";
import AccountIdCardContent from "@/components/clients/account/AccountIdCardContent";

export default {
  name: "AccountInformation",
  components: { BaseCard, AccountIdCardContent },
  computed: {
    ...mapGetters("clients", ["selectedClient"])
  }
};
</script>

<style scoped></style>
