<template>
  <BaseCard title="Invoice Terms" step-component="InvoiceTermsStep">
    <v-row>
      <v-col> Receive Invoices Via: {{ text }} </v-col>
    </v-row>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/registration/ReviewStepCards/BaseCard";
import { mapGetters, mapState } from "vuex";
export default {
  name: "InvoiceTerms",
  components: { BaseCard },
  computed: {
    ...mapGetters("baseData", ["getById"]),
    ...mapState("clients", ["invoice_communication_types_selected"]),
    text() {
      return (
        this.invoice_communication_types_selected
          .reduce((output, type_id) => {
            output += this.getById("communication_types", type_id).name + ", ";
            return output;
          }, "")
          // Trim whitespace
          .trim()
          // Trim commas
          .replace(/(^,)|(,$)/g, "")
      );
    }
  }
};
</script>

<style scoped></style>
