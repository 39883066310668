<template>
  <v-row>
    <v-col>
      <v-checkbox
        label="Billing address is associated with an existing account"
        v-model="third_party_billing"
      />
      <template v-if="third_party_billing && !checkboxOnly">
        <v-text-field
          :value="billingAccount"
          label="Selected Billing Account Number"
          :rules="rules.required"
          class="selectedBilling"
          :disabled="true"
        ></v-text-field>
        <v-btn
          v-show="hasBillingAccount"
          color="primary"
          :loading="loading"
          @click="resetBillingAccount"
          class="mb-2"
        >
          Change Billing Account
        </v-btn>
        <template v-if="!hasBillingAccount">
          <v-text-field
            ref="searchField"
            label="Account Number or Email"
            v-model="search"
            v-on:keydown.enter="verifyBillingAccount"
          ></v-text-field>
          <v-btn
            color="primary"
            :loading="loading"
            @click="verifyBillingAccount"
            class="mb-2"
          >
            Apply
          </v-btn>
        </template>
        <div class="error--text" v-if="billingMessage">
          {{ billingMessage }}
        </div>
        <p v-if="hasBillingAccount">
          An e-mail will be sent to the billing account for approval
        </p>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "BillingAccount",
  props: ["checkboxOnly"],
  data() {
    return {
      loading: false,
      search: "",
      rules: {
        required: [v => !!v || "Please apply a valid account"]
      },
      billingMessage: ""
    };
  },
  computed: {
    ...mapFields("clients", [
      "items[0].third_party_billing",
      "items[0].clients_third_party_billing"
    ]),
    billingAccount() {
      if (this.clients_third_party_billing) {
        return (
          this.clients_third_party_billing.billing_only_account_number || ""
        );
      }
      return "";
    },
    hasBillingAccount() {
      return (
        this.clients_third_party_billing &&
        this.clients_third_party_billing.billing_only_account_number
      );
    }
  },
  methods: {
    ...mapActions("clients", ["getBillingOnlyClientId", "initClientAddresses"]),
    ...mapMutations("clients", ["addThirdPartyBilling", "removeAddress"]),
    resetBillingAccount() {
      this.addThirdPartyBilling(false);
    },
    verifyBillingAccount() {
      this.billingMessage = "";
      this.loading = true;

      this.getBillingOnlyClientId(this.search).then(result => {
        this.search = ""; // Clear search field
        this.loading = false;
        if (!result) {
          this.billingMessage = "Billing account could not be located";
        }
      });
    }
  },
  watch: {
    third_party_billing() {
      this.initClientAddresses();
    }
  }
};
</script>

<style>
.selectedBilling .v-text-field__details * {
  color: red !important;
}
</style>
