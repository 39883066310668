<template>
  <v-row>
    <v-col>
      <v-card :flat="flat !== undefined || flat === true">
        <v-app-bar v-if="!!title" flat color="transparent">
          <v-toolbar-title class="title">
            {{ title }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn v-if="edit !== false" icon @click.stop="goToStep">
            <v-icon>{{ editIcon || "mdi-square-edit-outline" }}</v-icon>
          </v-btn>
        </v-app-bar>
        <v-divider></v-divider>
        <v-container>
          <slot></slot>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  name: "BaseCard",
  props: ["title", "edit", "editIcon", "stepComponent", "flat"],
  computed: {
    ...mapGetters("registration", {
      getStepId: "stepId"
    }),
    ...mapFields("registration", ["wizard.currentStep", "wizard.returnStep"]),
    stepId() {
      return this.getStepId(this.stepComponent);
    }
  },
  methods: {
    goToStep() {
      this.returnStep = this.currentStep; // Return to currently activated step when we're done editing
      this.currentStep = parseInt(this.stepId); // Go to the step defined in the prop, and make sure it's a number
    }
  }
};
</script>

<style scoped></style>
