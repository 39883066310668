<template>
  <BaseCard title="Lab Selection" :step-component="'LabSelectionStep'">
    <v-row>
      <v-col cols="12" class="pb-0">
        Lab Name:
        {{ (getById("labs", selectedClient.lab_id) || {}).title || "N/A" }}
      </v-col>
      <v-col cols="12" class="pb-0">
        Lab ID: {{ selectedClient.lab_id }}
      </v-col>
    </v-row>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/registration/ReviewStepCards/BaseCard";
import { mapGetters } from "vuex";
export default {
  name: "LabSelection",
  components: { BaseCard },
  computed: {
    ...mapGetters("clients", ["selectedClient"]),
    ...mapGetters("baseData", ["getById"])
  }
};
</script>

<style scoped></style>
