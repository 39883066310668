<template>
  <StepComponent>
    <ClientAddressesContainer />
  </StepComponent>
</template>

<script>
import ClientAddressesContainer from "@/components/clients/Addresses/ClientAddressesContainer";
import StepComponent from "@/components/registration/AccountSetupSteps/StepComponent";
// import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import { mapActions /* mapGetters, mapMutations */ } from "vuex";
// import rules from "@/utils/rules";

export default {
  name: "AddressStep",
  components: { StepComponent, ClientAddressesContainer },
  methods: {
    ...mapActions("clients", ["updateClientAddresses"]),
    beforeContinue() {
      return this.updateClientAddresses().catch(e => {
        console.error(e);
        return false;
      });
    }
  }
};
</script>
