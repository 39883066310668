<template>
  <StepComponent>
    <v-row>
      <v-col cols="12" sm="6">
        <vue-tel-input-vuetify
          v-model="workPhone"
          label="Work Phone"
          :rules="rules.phone"
          v-bind="bindProps"
        ></vue-tel-input-vuetify>
      </v-col>
      <v-col cols="12" sm="6">
        <vue-tel-input-vuetify
          v-model="homePhone"
          label="Home Phone"
          :rules="rules.phone"
          v-bind="bindProps"
        ></vue-tel-input-vuetify>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <vue-tel-input-vuetify
          v-model="mobilePhone"
          label="Mobile Phone"
          :rules="rules.phone"
          v-bind="bindProps"
        ></vue-tel-input-vuetify>
      </v-col>
      <v-col cols="12" sm="6">
        <vue-tel-input-vuetify
          v-model="faxNumber"
          label="Fax Number"
          v-bind="bindProps"
          :rules="rules.fax"
        ></vue-tel-input-vuetify>
      </v-col>
    </v-row>
    <v-row v-for="(email, index) in clients_email_addresses" :key="index">
      <v-col :cols="index === 0 ? 11 : 6">
        <v-text-field
          type="email"
          v-model="email.email_address"
          :rules="rules.email"
          :label="emailLabel(index)"
        ></v-text-field>
      </v-col>
      <v-col cols="4" v-if="index > 0">
        <v-checkbox
          v-model="email.receive_copy_of_all"
          label="Receive Copy of Primary Email"
        ></v-checkbox>
      </v-col>
      <v-col cols="1" v-if="index > 0">
        <v-icon @click="deleteEmail(index)">mdi-minus</v-icon>
      </v-col>
      <v-col cols="1">
        <v-icon @click="addEmail">mdi-plus</v-icon>
      </v-col>
    </v-row>
  </StepComponent>
</template>

<script>
import StepComponent from "@/components/registration/AccountSetupSteps/StepComponent";
import { mapActions, mapMutations } from "vuex";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import rules from "@/utils/rules";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import "vuetify/dist/vuetify.min.css";

export default {
  name: "ContactInformationStep",
  components: { StepComponent, VueTelInputVuetify },
  data() {
    return {
      rules: {
        email: [rules.required, rules.email],
        required: [rules.required],
        phone: [
          number =>
            /^\s*[-()0-9+ ]+\s*$/.test(number) ||
            number === "" ||
            "Invalid phone number (digits only allowed). Keep adding digits to remove invalid characters",
          rules.required
        ],
        fax: [
          number =>
            /^\s*[-()0-9+ ]+\s*$/.test(number) ||
            number === "" ||
            "Invalid fax number (digits only allowed). Keep adding digits to remove invalid characters"
        ]
      },
      bindProps: {
        mode: "international",
        disabledFetchingCountry: true,
        defaultCountry: "US"
      }
    };
  },
  computed: {
    workPhone: {
      get() {
        return this.work_phone || "";
      },
      set(val) {
        this.work_phone = val;
      }
    },
    homePhone: {
      get() {
        return this.home_phone || "";
      },
      set(val) {
        this.home_phone = val;
      }
    },
    mobilePhone: {
      get() {
        return this.mobile_phone || "";
      },
      set(val) {
        this.mobile_phone = val;
      }
    },
    faxNumber: {
      get() {
        return this.fax || "";
      },
      set(val) {
        this.fax = val;
      }
    },
    ...mapFields("clients", [
      "items[0].work_phone",
      "items[0].home_phone",
      "items[0].mobile_phone",
      "items[0].fax"
    ]),
    ...mapMultiRowFields("clients", ["items[0].clients_email_addresses"])
  },
  methods: {
    ...mapMutations("clients", ["addEmail", "deleteEmail"]),
    ...mapMutations("clients", { workPhoneMutation: "updateWorkPhone" }),
    ...mapActions("clients", ["getClients", "updateClientContactInfo"]),
    emailLabel(idx = 0) {
      return idx === 0 ? "Primary Email" : "Email";
    },
    beforeContinue() {
      return this.updateClientContactInfo().catch(e => {
        console.error(e);
        return false;
      });
    }
  },
  mounted() {
    Promise.all([this.getClients()]).then(() => {
      if (this.clients_email_addresses.length === 0) {
        this.addEmail();
        this.clients_email_addresses[0]["email_address"] =
          this.$store.state.auth.user.email ||
          this.$store.state.auth.user.username;
      }
    });
  }
};
</script>
