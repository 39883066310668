<template>
  <BaseCard title="Addresses" :step-component="'AddressStep'">
    <v-row v-for="address in addresses" :key="address.clients_address_type_id">
      <v-col cols="12" class="text-h6 pb-0 pt-2">
        {{
          address.clients_address_type_id === 0
            ? "Main"
            : (
                getById(
                  "clients_address_types",
                  address.clients_address_type_id
                ) || {}
              ).title
        }}
        Address
      </v-col>
      <v-col cols="12" v-if="address.third_party_billing">
        Billing Account #{{
          address.clients_third_party_billing.billing_only_account_number
        }}
      </v-col>
      <v-col cols="12" v-else>
        {{ address.address_1 }}<br />
        <template v-if="address.address_2 && address.address_2.length">
          {{ address.address_2 }}<br />
        </template>
        {{ address.city }},
        {{ (getById("states", address.state_id) || {}).abbr }}
        {{ address.postal_code }}<br />
        {{ (getById("countries", address.country_id) || {}).country_name }}
      </v-col>
    </v-row>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/registration/ReviewStepCards/BaseCard";
import { mapGetters } from "vuex";
import pick from "lodash.pick";

export default {
  name: "Addresses",
  components: { BaseCard },
  computed: {
    ...mapGetters("baseData", ["getById"]),
    ...mapGetters("clients", ["selectedClient"]),
    addresses() {
      let addresses = [
        {
          clients_address_type_id: 0,
          ...pick(this.selectedClient, [
            "address_1",
            "address_2",
            "country_id",
            "city",
            "state_id",
            "postal_code"
          ])
        },
        ...this.selectedClient.clients_addresses
      ];

      const billingAddress = this.selectedClient.clients_addresses.find(
        address => address.clients_address_type_id === 1
      );

      if (!billingAddress) {
        if (this.selectedClient.third_party_billing) {
          addresses.push({
            clients_address_type_id: 1,
            third_party_billing: this.selectedClient.third_party_billing,
            clients_third_party_billing: this.selectedClient
              .clients_third_party_billing
          });
        }
      }

      return addresses;
    }
  }
};
</script>

<style scoped></style>
